import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "mr-genius-admin-panel";
export const domainName = "ahmed-tarek.net";
export const isManualPayment = false;
export const isCouponable = false;
export const isMultiYear = true;
export const isCodes = true;
export const isBtns = false;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "yellow";
export const headerSectionColor = "yellow";
export const navbarIconColor = "text-yellow-500";
export const navbarDisclosureColor = "yellow";

export const progressBarColor = "yellow";
export const loadingBarColor = "yellow";

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = false;
export const prepaidInfoColor = "yellow";

export const autoChangeRandomNames = false;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "yellow";
export const registerPageColor = "blue";

export const isAnalytics = false;
export const isAbleChangingPassword = false;

export const isMultiAdmin = true;

export const isCategories = true;
export const isCollapseCategories = false;
