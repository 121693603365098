import { Icon } from "@iconify/react";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import CourseContext from "../../context/CourseContext";

import auth from "../../services/authServices";
import http from "../../services/httpServices";
import modal from "../../services/modalServices";

import Form from "../../components/form/elements/Form";
import InputField from "../../components/form/elements/InputField";
import Button from "../../components/ui/Button";
import CenterIcon from "../../components/ui/CenterIcon";
import Container from "../../components/ui/Container";
import FlexRowReverse from "../../components/ui/FlexRowReverse";
import a from "../../services/analyticsServices";
import { isCouponable } from "../../services/defaultSettings";

const SubscriptionInvoice = () => {
    const [discount, setDiscount] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [coupon, setCoupon] = useState("");
    const [error, setError] = useState("");
    const [result, setResult] = useState("");

    const [subscribeButtonLoading, setSubscribeButtonLoading] = useState(false);

    const { course } = useContext(CourseContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (result) {
            setCoupon("");
            setDiscount(0);
            setResult("");
            setIsDisabled(false);
            return;
        }
        setError("");
        setLoading(true);
        setIsDisabled(true);

        if (!coupon) {
            return setError("الرجاء ملئ الكوبون");
        }
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        try {
            const { data } = await http.post(
                `/api/sellables/course/${course.id}/validate_coupon`,
                { coupon },
                config
            );
            setResult(data.message);
            setDiscount(data.discount);
            setLoading(false);
            // setIsDisabled(false);
        } catch ({ response }) {
            setError(response.data.errors.coupon[0]);
            setLoading(false);
            setIsDisabled(false);
        }

        // if()
    };
    const SubscriptionInstruction = () => {
        modal.message({
            title: "شروط الاشتراك فى الكورس",
            text: `الكورس متاح لشخص واحد فقط ولا يجوز مشاركته مع طالب اخر`,
            icon: "warning",

            buttons: {
                confirm: " اوافق على الشروط",
                cancel: "الغاء",
            },
            callback: (e) => {
                if (e && e !== "cancel") {
                    createInvoice();
                }
            },
        });
    };
    const createInvoice = async () => {
        setSubscribeButtonLoading(true);
        let data = {};
        if (discount) {
            data = { coupon };
        }
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const visitorVisitId = a.getVisitorVisit();
        try {
            const { data: result } = await http.post(
                `/api/sellables/course/${course.id}/subscribe_request`,
                { ...data, visitor_visit_id: visitorVisitId },
                config
            );
            window.location.href = result.url;
        } catch ({ response }) {
            modal.message({
                title: "حدث خطأ اثناء انشاء الفاتورة ",
                text: "يرجى الاتصال بالدعم",
                icon: "error",
            });
            setSubscribeButtonLoading(false);
        }
    };
    return (
        <Container>
            <div className="relative z-10 bg-inner-container smooth clr-text-primary rounded-lg shadow-large p-10 -mt-52">
                <Link element="Link" className="underline clr-text-secondary smooth" to={-1}>
                    <FlexRowReverse>
                        <CenterIcon icon="akar-icons:arrow-right" />
                        <span>العودة للوراء</span>
                    </FlexRowReverse>
                </Link>
                <div className="font-h1 flex-center-both pb-10 font-w-bold">
                    <div className="">الفاتورة</div>
                </div>
                {course.is_couponable && isCouponable ? (
                    <div>
                        <div>
                            هل لديك كوبون؟{" "}
                            <span className="text-cyan-500 underline"> انسخه الآن ! </span>
                        </div>

                        <Form onSubmit={handleSubmit}>
                            <div className="flex flex-row items-end space-x-2 space-x-reverse  pb-10 pt-8">
                                <div>
                                    <Button
                                        isLoading={loading}
                                        color={result ? "teal" : "yellow"}
                                        className="shrink-0 rounded-l-none rounded-r-md"
                                    >
                                        {result && "تعديل"}
                                        {!result && "تطبيق"}
                                    </Button>
                                </div>
                                <InputField
                                    className="max-w-sm"
                                    placeholder="الكوبون الخاص بك"
                                    icon={
                                        <span className="flex-center-both mb-1">
                                            <Icon icon="bi:gift-fill" />
                                        </span>
                                    }
                                    value={coupon}
                                    onChange={({ currentTarget: input }) => {
                                        setCoupon(input.value.toUpperCase());
                                    }}
                                    isDisabled={isDisabled}
                                />
                                {error && (
                                    <div className="bg-rose-800 bg-opacity-10 border border-rose-500 rounded-md px-5 py-2 text-rose-500">
                                        {error}
                                    </div>
                                )}
                                {result && (
                                    <div className="bg-teal-300 bg-opacity-10 border border-teal-500 rounded-md px-5 py-2 text-teal-500">
                                        {result}
                                    </div>
                                )}
                            </div>
                        </Form>
                    </div>
                ) : null}
                <div>
                    <table className="table-auto w-full table-style">
                        <thead className="py-10">
                            <tr>
                                <th className="h-20 text-center">سعر الكورس</th>
                                <th className="h-20 text-center">الخصم</th>
                                <th className="h-20 text-center">
                                    اجمالي سعر
                                    <br />
                                    الفاتورة النهائي
                                </th>
                            </tr>
                        </thead>
                        <tbody className="py-10">
                            <tr>
                                <td className="h-20 text-center">{course.price} جنيهًا</td>
                                <td className="h-20 text-center">{discount} جنيهًا</td>
                                <td className="h-20 text-center">
                                    {course.price - discount} جنيهًا
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="flex-center-both pt-10">
                    <div className="w-full max-w-lg">
                        <Button
                            color="cyan"
                            className="w-full"
                            onClick={SubscriptionInstruction}
                            isLoading={subscribeButtonLoading}
                        >
                            الذهاب للدفع
                        </Button>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default SubscriptionInvoice;
