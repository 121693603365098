import React, { useContext, useState } from "react";
import Electric from "../../components/svgs/Electric";

import AuthContext from "../../context/AuthContext";

import "./MainSection.css";

import Button from "../../components/ui/Button";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
// import Waleed from "../../components/svgs/Waleed";
// import YearsBooks from "../../components/svgs/YearsBooks";
// import Shapes from "../../components/svgs/Shapes";
// import Waves from "../../components/ui/Waves";
import profile from "../../assets/profile.png";
import profileLight from "../../assets/profile-light.png";
import bg from "../../assets/bg-star.png";
import ThemeContext from "../../context/ThemeContext";
const MainSection = ({ title = false }) => {
    const { token } = useContext(AuthContext);

    let { darkmode } = useContext(ThemeContext);

    return (
        <>
            <section className="md:h-screen min-h-screen negative-nav-margin relative overflow-hidden">
                <div
                    className="inset-0 w-full h-full z-0 absolute opacity-100"
                    style={{
                        backgroundImage: "url(" + bg + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center top",
                        // backgroundRepeat: "repeat-y",
                    }}
                ></div>
                <div
                    className="inset-0 w-full h-full z-0 absolute opacity-100 dark:opacity-0 smooth"
                    style={{
                        backgroundImage: "url(" + bg + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center top",
                        // backgroundRepeat: "repeat-y",
                    }}
                ></div>
                <Container className="h-full relative z-10 font-fs pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row flex-col h-full space-y-20 md:space-y-0">
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both en">
                            <div className="flex items-center md:items-start justify-center  flex-col  mooth space-y-6 md:space-y-0">
                                {/* <h2 className="font-h1 font-w-bold">منصة</h2> */}
                                <h1 className="font-bigmax font-w-bold before-box relative">
                                    <span className="relative z-10">
                                        <span>
                                            Mr. Ahmed <span className="text-blue-500">Tarek</span>
                                        </span>
                                    </span>
                                </h1>
                                <div className="h-20 w-1 bg-yellow-500"></div>
                                <div className="">
                                    <h2 className="font-h2 text-yellow-500 inline pr-2 font-borel">
                                        Mr Genius
                                    </h2>
                                    <span className="font-h2 relative">
                                        Platform For Teaching English
                                    </span>
                                </div>
                                <div className="font-h2">Curriculum For Secondary School</div>
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex-center-both max-w-md">
                                <img
                                    src={darkmode ? profile : profileLight}
                                    alt={"profile"}
                                    className=""
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
